
import ProgrammingLanguageWidget from '../components/ProgrammingLanguageWidget';
import SponsorWidget from "../components/SponsorWidget";


import programmingLanguageFunction from "../hooks/programmingLanguageFunction";
import ProjectCard from '../components/ProjectCard';


import { FaLaptopCode } from "react-icons/fa";
import { HiOutlineEmojiHappy } from "react-icons/hi";
import { FaRegHandshake } from "react-icons/fa";
import React, {useEffect} from "react";
import { Link } from 'react-router-dom';


const Projects = () => {
    const words = ['WORK!', 'HELP!']; // Words to alternate
    const { openText, handleSubmit } = programmingLanguageFunction(words); // Use the custom hook

    useEffect(() => {
        window.scrollTo(0, 0); // Hacer scroll a la parte superior al montar el componente
    }, []);

    const sideHustles = [
        {
            title: 'Fundación VOS',
            description: 'Website Development, support in the digitization of the foundation, creation of forms, CRM, and solution for receiving online payments. ',
            imgSrc: <FaRegHandshake color="#0ea5e9" size="20px" />,
            imgAlt: 'kids-Foundation',
            tag: 'Non-profit organization',
        },
        {
            title: 'Equilibrio App',
            description: 'PWA/Landing Page, frontend development for the apps, project management, and development of the concept and digital implementation.',
            imgSrc: <FaRegHandshake color="#0ea5e9" size="20px" />,
            imgAlt: 'Project 02',
            tag: 'Non-profit organization',
        },
        // Add more projects here
    ];

    const clientProjects = [
        {
            title: 'Personal Portfolio',
            description: 'A React app styled with Tailwind CSS, focused on creating new contacts, and seeking new projects, tasks, or job opportunities.',
            imgSrc: <HiOutlineEmojiHappy color="#0ea5e9" size="20px" />,
            imgAlt: 'Project 04',
            tag: 'Personal Development',
        },
        {
            title: 'MentaPixel',
            description: 'Developed a landing page in React.js, including design implementation and backend configuration in PHP for email processing.',
            imgSrc: <FaLaptopCode color="#0ea5e9" size="20px"/>,
            imgAlt: 'LandingPage',
            tag: 'Full-Stack Development Task',
        }
        // Add more projects here
    ];
    const codeText = [
        "<🚧>This section is still under construction; more projects and detailed information about each one are coming soon. </🚧>"
    ];
    const {openText: animatedCodeText} = programmingLanguageFunction(codeText, 60, 5); // Using hook for animated code text
    const splitTextProjects = animatedCodeText.split(/(<\/?🚧>)/g);

    return (
        <div>
            {/* Content*/}
            <div className="grow md:flex space-y-8 md:space-y-0 md:space-x-8  pb-16 md:pb-20">
                {/* Mitle area*/}
                <div className="grow">
                    <div className="max-w-[700px]">
                        <section>
                            <h1 className="h1 font-aspekta mb-12">Nice stuff I've built</h1>

                            {/* Animated Code Block */}
                            <pre
                                className="whitespace-pre-wrap text-sm text-black bg-gray-100 my-6 p-4 rounded leading-tight relative">
                                    <code className="font-pt-mono typing ">
                                        {splitTextProjects.map((part, index) => (
                                            <span
                                                key={index}
                                                className={part.includes('<p>') || part.includes('</p>') ? 'text-yellow-400' : ''}
                                            >
                                                {part}
                                            </span>
                                        ))}
                                        <span className="cursor">|</span>
                                    </code>
                                </pre>

                            {/* Client Projects Section */}
                            <section>
                                <h2 className="font-aspekta text-xl font-[650] mb-6">General Projects</h2>
                                <div className="grid sm:grid-cols-2 md:grid-cols-1 lg:grid-cols-2 gap-5">
                                    {clientProjects.map((project, index) => (
                                        <ProjectCard
                                            key={index}
                                            title={project.title}
                                            description={project.description}
                                            imgSrc={project.imgSrc}
                                            imgAlt={project.imgAlt}
                                            tag={project.tag}
                                        />
                                    ))}
                                </div>
                            </section>

                            {/* Side Hustles Section */}
                            <section>
                                <h2 className="font-aspekta my-6 text-xl font-[650]  ">Collaborations with Non-Profits</h2>
                                <div className="grid sm:grid-cols-2 md:grid-cols-1 lg:grid-cols-2 gap-5">
                                    {sideHustles.map((project, index) => (
                                        <ProjectCard
                                            key={index}
                                            title={project.title}
                                            description={project.description}
                                            imgSrc={project.imgSrc}
                                            imgAlt={project.imgAlt}
                                            tag={project.tag}
                                        />
                                    ))}
                                </div>
                            </section>
                        </section>
                    </div>
                </div>

                {/*Right sidebar*/}
                <aside className="md:w-[240px] lg:w-[300px] shrink-0">

                    <div className="space-y-6">

                        {/* Programming language widget*/}
                        <ProgrammingLanguageWidget openText={openText} handleSubmit={handleSubmit} />


                        {/* Sponsor widget */}
                        <SponsorWidget/>

                        { /*Popular services widget*/}
                        <div
                            className="rounded-lg border border-slate-200 dark:border-slate-800 dark:bg-gradient-to-t dark:from-slate-800 dark:to-slate-800/30 odd:rotate-1 even:-rotate-1 p-5">
                            <div className="font-aspekta font-[550] mb-3">Optimized Digital Solutions</div>
                            <ul className="space-y-3">
                                <li className="inline-flex">
                                    <span className="text-sky-500 mr-2">—</span>
                                    <p className="font-aspekta   text-sm inline-flex relative hover:text-sky-500 duration-150 ease-out before:scale-x-0 before:origin-center before:absolute before:inset-0 before:bg-sky-200 dark:before:bg-sky-500 before:opacity-30 before:-z-10 before:translate-y-1/4 before:-rotate-2 hover:before:scale-100 before:duration-150 before:ease-in-out">
                                        Custom Applications
                                    </p>
                                </li>
                                <li className="inline-flex">
                                    <span className="text-sky-500 mr-2">—</span>
                                    <p className="font-aspekta   text-sm inline-flex relative hover:text-sky-500 duration-150 ease-out before:scale-x-0 before:origin-center before:absolute before:inset-0 before:bg-sky-200 dark:before:bg-sky-500 before:opacity-30 before:-z-10 before:translate-y-1/4 before:-rotate-2 hover:before:scale-100 before:duration-150 before:ease-in-out">
                                        Consulting on digital strategy
                                    </p>
                                </li>
                                <li className="inline-flex">
                                    <span className="text-sky-500 mr-2">—</span>
                                    <p className="font-aspekta   text-sm inline-flex relative hover:text-sky-500 duration-150 ease-out before:scale-x-0 before:origin-center before:absolute before:inset-0 before:bg-sky-200 dark:before:bg-sky-500 before:opacity-30 before:-z-10 before:translate-y-1/4 before:-rotate-2 hover:before:scale-100 before:duration-150 before:ease-in-out">
                                        Integrating e-commerce solutions
                                    </p>
                                </li>
                                <li className="inline-flex">
                                    <span className="text-sky-500 mr-2">—</span>
                                    <p className="font-aspekta   text-sm inline-flex relative hover:text-sky-500 duration-150 ease-out before:scale-x-0 before:origin-center before:absolute before:inset-0 before:bg-sky-200 dark:before:bg-sky-500 before:opacity-30 before:-z-10 before:translate-y-1/4 before:-rotate-2 hover:before:scale-100 before:duration-150 before:ease-in-out">
                                        Developing interactive websites
                                    </p>
                                </li>
                                <li className="inline-flex">
                                    <span className="text-sky-500 mr-2">—</span>
                                    <p className="font-aspekta text-sm inline-flex relative hover:text-sky-500 duration-150 ease-out before:scale-x-0 before:origin-center before:absolute before:inset-0 before:bg-sky-200 dark:before:bg-sky-500 before:opacity-30 before:-z-10 before:translate-y-1/4 before:-rotate-2 hover:before:scale-100 before:duration-150 before:ease-in-out">
                                        Providing ongoing technical support
                                    </p>
                                </li>
                            </ul>

                        </div>

                    </div>

                </aside>

            </div>


            <footer className="border-t border-slate-200 dark:border-slate-800">
                <div className="py-8">
                    <div className="text-center md:flex md:items-center md:justify-between">

                        {/* Social links */}
                        <ul className="inline-flex mb-4 md:order-1 md:ml-4 md:mb-0 space-x-2">
                            <li>
                                <a className="flex justify-center items-center text-sky-500 hover:text-sky-600 transition duration-150 ease-in-out"
                                   href="https://www.linkedin.com/in/oscar-lee-corradine-294073191" target="_blank" rel="noopener noreferrer"  aria-label="LinkedIn">
                                    <svg className="w-4 h-4 fill-current" viewBox="0 0 32 32" xmlns="http://www.w3.org/2000/svg">
                                        <path
                                            d="M27 0H5C2.24 0 0 2.24 0 5v22c0 2.76 2.24 5 5 5h22c2.76 0 5-2.24 5-5V5c0-2.76-2.24-5-5-5zM10.48 27H5.99V12h4.49v15zM8.24 10.29c-1.44 0-2.61-1.17-2.61-2.61s1.17-2.61 2.61-2.61c1.44 0 2.61 1.17 2.61 2.61s-1.17 2.61-2.61 2.61zm19.26 16.71h-4.49V20.4c0-1.58-.03-3.61-2.2-3.61-2.2 0-2.54 1.72-2.54 3.5v7.71h-4.49V12h4.31v2.05h.06c.6-1.14 2.07-2.34 4.26-2.34 4.56 0 5.4 3 5.4 6.94v8.34z">
                                        </path>
                                    </svg>
                                </a>
                            </li>
                            <li>
                                <Link className="flex justify-center items-center text-sky-500 hover:text-sky-600 transition duration-150 ease-in-out"
                                      to='/contact' aria-label="Custom Icon">
                                    {/* Additional Icon */}
                                    <svg
                                        className="w-4 h-4 fill-current"
                                        xmlns="http://www.w3.org/2000/svg"
                                        viewBox="0 0 21 21"
                                    >
                                        <path fillOpacity=".16" d="m13.4 18-3-7.4-7.4-3L19 2z" />
                                        <path d="M13.331 15.169 17.37 3.63 5.831 7.669l5.337 2.163 2.163 5.337Zm-3.699-3.801L.17 7.53 20.63.37l-7.161 20.461-3.837-9.463Z" />
                                    </svg>
                                </Link>
                            </li>
                        </ul>

                        {/* Copyright */}
                        <div className="text-sm text-slate-500 dark:text-slate-400">&copy; 2024 Oscar Lee Corradine. All rights reserved.
                        </div>

                    </div>
                </div>
            </footer>
        </div>

    );
};

export default Projects;
