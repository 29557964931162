// src/hooks/ProgrammingLanguageFunction.js
import { useState, useEffect } from 'react';

const ProgrammingLanguageFunction = (words, initialSpeed = 150, deleteSpeed = 50) => {
    const [openText, setText] = useState('');
    const [isDeleting, setIsDeleting] = useState(false);
    const [loopNum, setLoopNum] = useState(0);
    const [typingSpeed, setTypingSpeed] = useState(initialSpeed); // Use initialSpeed

    useEffect(() => {
        const handleTyping = () => {
            const i = loopNum % words.length;
            const fullText = words[i];

            setText(isDeleting ? fullText.substring(0, openText.length - 1) : fullText.substring(0, openText.length + 1));

            if (!isDeleting && openText === fullText) {
                setTimeout(() => setIsDeleting(true), 700); // Wait before deleting
            } else if (isDeleting && openText === '') {
                setIsDeleting(false);
                setLoopNum(loopNum + 1);
            }

            setTypingSpeed(isDeleting ? deleteSpeed : initialSpeed); // Use deleteSpeed for deleting
        };

        const typingTimeout = setTimeout(handleTyping, typingSpeed);

        return () => clearTimeout(typingTimeout);
    }, [openText, isDeleting, loopNum, typingSpeed, words, initialSpeed, deleteSpeed]);

    return { openText };
};

export default ProgrammingLanguageFunction;
